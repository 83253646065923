import React from "react";
import Container from "../../components/container";
import { navigate } from "gatsby";
import OneQuestionForm from "../../components/OneQuestionForm";

export default function Index() {
  return (
    <Container>
      <section className="flex-col flex items-center md:justify-between mt-16 mb-16 md:mb-12">
        <h1 className="text-6xl font-bold tracking-tighter leading-tight">
          Taza Socorrismo.
        </h1>
        <h4 className="text-center md:text-left text-lg mt-5">
          Así es cómo os conocisteis. Lo típico sería que os preguntásemos
          cuánto sabéis el uno del otro. Pero lo que vamos a hacer es averiguar
          cuánto sabéis del mundo.
        </h4>
        <img
          className="mt-5 rounded-2xl "
          src="/salvamento.png"
          title="Socorrismo"
          alt="Socorrismo"
        />
        {/*<OneQuestionForm*/}
        {/*  question="Seguro que adivináis la contraseña para entrar?"*/}
        {/*  validateResponse={(input) => {*/}
        {/*    let normalizedInput = input*/}
        {/*      .normalize("NFKD")*/}
        {/*      .replace(/[\u0300-\u036f]/g, "");*/}
        {/*    return (*/}
        {/*      normalizedInput.toLowerCase().includes("frigsa 2004") &&*/}
        {/*      normalizedInput.toLowerCase().includes("2004")*/}
        {/*    );*/}
        {/*  }}*/}
        {/*  onSuccess={() => navigate("/taza2/compatibilidad")}*/}
        {/*/>*/}
      </section>
      <button
        onClick={() => navigate("/taza2/compatibilidad")}
        type="button"
        className=" my-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Continuar
      </button>
      {/*<RadioButtons question={questions[currentQuestion]} />*/}
      {/*<OneQuestionForm*/}
      {/*  validateResponse={validateResponse}*/}
      {/*  question="¿Qué prefiere Patricia, playa o montaña?"*/}
      {/*/>*/}
    </Container>
  );
}
